import { Field, FieldProps as FormikFieldProps } from 'formik'
import { useMemo } from 'react'
import { colors } from '~/styles/colors'

import { FieldProps } from '../field'
import { PopoverMenu } from '../popover-menu'
import { Meta } from '../typography'
import IconDropdown from './assets/icon-dropdown.svg'
import {
  ContentWrapper,
  IconWrapper,
  LabelWrapper,
  SelectMenuButton,
} from './style'

export type SelectProps<Value = string> = FieldProps & {
  value: Value
  options: Array<{
    label: string
    value: Value
    icon?: any
    href?: string
    filter?: any
  }>
  onSelect: (value: Value) => void
  placeholder?: string
  disabled?: boolean
  icon?: any
  staticIcon?: boolean
  large?: boolean,
  className?: string
}

export const Select = <Value extends unknown = string>({
  options,
  value,
  onSelect,
  placeholder,
  disabled,
  icon,
  staticIcon,
  ...field
}: SelectProps<Value>) => {
  const active = options.find(option => option.value === value)
  const items = useMemo(
    () =>
      options.map(option => {
        return (
          <PopoverMenu.Item
            key={String(option.value)}
            onClick={() => {
              onSelect(option.value)
            }}
            selected={option.value === value}
            label={option.label}
            icon={option?.icon}
            href={option?.href}
            filter={option?.filter}
          />
        )
      }),
    [options.length, active]
  )

  return (
    <PopoverMenu
      button={
        <SelectMenuButton
          {...field}
          isIconStatic={staticIcon}
          disabled={disabled}
          icon={icon ?? <IconDropdown />}
        >
          {active?.label ? (
            <>
              {active.icon ? (
                <ContentWrapper>
                  <IconWrapper>{active?.icon}</IconWrapper>
                  <LabelWrapper>{active.label}</LabelWrapper>
                </ContentWrapper>
              ) : (
                <>{active.label}</>
              )}
            </>
          ) : (
            <Meta regular color={colors.slateGray}>
              {placeholder}
            </Meta>
          )}
        </SelectMenuButton>
      }
      items={items}
    />
  )
}

type SelectFieldProps<Value> = FieldProps & {
  name: string
  options: SelectProps<Value>['options']
  placeholder?: string
  disabled?: boolean
  autoComplete?: string
  large?: boolean
}

export const SelectField = <Value extends unknown>({
  name,
  ...rest
}: SelectFieldProps<Value>) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: FormikFieldProps) => {
        return (
          <Select<Value>
            value={field.value}
            onSelect={value => form.setFieldValue(name, value)}
            error={meta.touched && meta.error ? meta.error : undefined}
            {...rest}
          />
        )
      }}
    </Field>
  )
}

export const useSelectOptions = <T,>(
  data: T[] | undefined,
  mapper: (item: T) => SelectProps['options'][0]
): SelectProps['options'] => {
  return useMemo(() => (!data ? [] : data.map(mapper)), [data])
}
